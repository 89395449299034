/* eslint react/destructuring-assignment: 0 */
import React from 'react'
import { graphql } from 'gatsby'
import Post from './post'

const widgetOptions = {
  filters: true,
  mode: 'collapsed-items',
  items: 5,
}

const PostCountry = props => <Post {...props} widgetOptions={widgetOptions} />

export const pageQueryBlogPost = graphql`
  query PostBlogById($id: String!, $locale: String!) {
    post: prismicBlog(id: { eq: $id }, lang: { eq: $locale }) {
      id
      first_publication_date
      last_publication_date
      data {
        title
        description
        keywords
        body {
          ... on PrismicBlogBodyText {
            id
            slice_type
            primary {
              text {
                raw {
                  type
                  spans {
                    data {
                      id
                      url
                      uid
                      slug
                      link_type
                      lang
                      type
                    }
                    type
                    end
                    start
                  }
                  text
                }
                text
              }
            }
          }
          ... on PrismicBlogBodyWidget {
            id
            slice_type
          }
          ... on PrismicBlogBodyLeftText {
            slice_type
            id
            primary {
              text {
                html
              }
            }
          }
          ... on PrismicBlogBodyPreface {
            slice_type
            id
            primary {
              text {
                html
              }
            }
          }
          ... on PrismicBlogBodyImage {
            slice_type
            id
            primary {
              image {
                url
                alt
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1200, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                    sizes(maxWidth: 700) {
                      ...GatsbyImageSharpSizes_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    generators: allPrismicGenerator(filter: { uid: { eq: "blog" }, lang: { eq: $locale } }) {
      edges {
        node {
          data {
            formula
            formula_slug
          }
          uid
        }
      }
    }
    allPostCountry: allPrismicPostCountry(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          id
          data {
            country {
              document {
                uid
              }
            }
          }
        }
      }
    }
  }
`

// PostCountry.query = pageQueryCountry

export default PostCountry
